import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState } from "react";

function HomeVideo() {
  const YOUTUBE_PLAYLIST_ITEMS_API = 'https://www.googleapis.com/youtube/v3/playlistItems';
  const [youTubedata, setyouTubedata] = useState([]);
  const getYoutubePlayList = async () => {
    const res = await fetch(`${YOUTUBE_PLAYLIST_ITEMS_API}?part=snippet&playlistId=PLeTyYUgffKJA2VrNsvOD9UGuWB-LWxuvH&key=${process.env.REACT_APP_YOUTUBE_API_KEY}`);
    const data = await res.json();
    setyouTubedata(data.items)
  }

  useEffect(() => {
    getYoutubePlayList();
  }, [])

  return (
    <div >
      {
        youTubedata.map((item ,i) => {
          const { snippet = {} } = item;
          const { title, thumbnails = {}, resourceId } = snippet;
          const { standard = {} } = thumbnails;
          return (
            <div key={i} className='visme-video-hero'>
              <div className='visme-video-hero__video-controls'></div>
              <iframe className="visme-video-hero__video d-block mx-auto" width="1080" height="608" src={`https://www.youtube.com/embed/${resourceId.videoId}`} title={title}  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen />
            </div>
          );
        })
      }
    </div>
  );
}

export default HomeVideo;